import React from 'react';
import './index.css';

const Routes = () => {
    return (
        <div className='routes'>
            Routes
        </div>
    )
};

export default Routes;