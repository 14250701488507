import React from 'react';
import './index.css';

const Contact = () => {
    return (
        <div className='contact'>
            Contact
        </div>
    )
};

export default Contact;